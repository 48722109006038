import { createRouter, createWebHistory } from 'vue-router'
const HomeView = () => import('../views/HomeView.vue')
const LearnHiragana = () => import('../views/LearnHiragana.vue')
const LearnKatakana = () => import('../views/LearnKatakana.vue')
const LearnKanjiTop = () => import('../views/LearnKanjiTop.vue')
const LearnKanji = () => import('../views/LearnKanji.vue')
const PrivacyPolicy = () => import('../views/PrivacyPolicy.vue')
import Hiragana from '../assets/Hiragana.json'
import KanjiData1 from '../assets/grade1.json'
import KanjiData2 from '../assets/grade2.json'
import KanjiData3 from '../assets/grade3.json'
import KanjiData4 from '../assets/grade4.json'
import KanjiData5 from '../assets/grade5.json'
import KanjiData6 from '../assets/grade6.json'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Nihongo Stroke Order',
      metaTags: [
        {
          name: 'description',
          content: 'Learn how to write Japanese Hiragana, Katakana, and 1026 Jōyō Kanji with stroke order animations!'
        },
        {
          property: 'og:description',
          content: 'Learn how to write Japanese Hiragana, Katakana, and 1026 Jōyō Kanji with stroke order animations!'
        }
      ]
    }
  },
  {
    path: '/hiragana/',
    name: 'hiragana',
    component: LearnHiragana,
    meta: {
      title: 'Hiragana Stroke Order',
      metaTags: [
        {
          name: 'description',
          content: 'Learn how to write 48 Japanese Hiragana with stroke order animations!'
        },
        {
          property: 'og:description',
          content: 'Learn how to write 48 Japanese Hiragana with stroke order animations!'
        }
      ]
    }
  },
  {
    path: '/hiragana/:character/',
    name: 'hiraganaItem',
    component: LearnHiragana,
    props: true,
    meta: {
      title: '',
      metaTags: [
        {
          name: 'description',
          content: ''
        },
        {
          property: 'og:description',
          content: ''
        }
      ]
    }
  },
  {
    path: '/katakana/',
    name: 'katakana',
    component: LearnKatakana,
    meta: {
      title: 'Katakana Stroke Order',
      metaTags: [
        {
          name: 'description',
          content: 'Learn how to write 48 Japanese Katakana with stroke order animations!'
        },
        {
          property: 'og:description',
          content: 'Learn how to write 48 Japanese Katakana with stroke order animations!'
        }
      ]
    }
  },
  {
    path: '/katakana/:character/',
    name: 'katakanaItem',
    component: LearnKatakana,
    props: true,
    meta: {
      title: '',
      metaTags: [
        {
          name: 'description',
          content: ''
        },
        {
          property: 'og:description',
          content: ''
        }
      ]
    }
  },
  {
    path: '/kanji/',
    name: 'kanji',
    component: LearnKanjiTop,
    meta: {
      title: 'Kanji Stroke Order',
      metaTags: [
        {
          name: 'description',
          content: 'Learn 1026 Jōyō Kanji with Stroke Order Animations, Readings, Radical, Stroke Count, Meanings in English and French.'
        },
        {
          property: 'og:description',
          content: 'Learn 1026 Jōyō Kanji with Stroke Order Animations, Readings, Radical, Stroke Count, Meanings in English and French.'
        }
      ]
    }
  },
  {
    path: '/kanji/grade1/',
    name: 'kanjiGrade1',
    component: LearnKanji,
    meta: {
      title: 'Grade 1 Kanji',
      metaTags: [
        {
          name: 'description',
          content: 'Learn 80 Jōyō Kanji taught in Grade 1 with Stroke Order Animations, Readings, Radical, Stroke Count, Meanings in English and French.'
        },
        {
          property: 'og:description',
          content: 'Learn 80 Jōyō Kanji taught in Grade 1 with Stroke Order Animations, Readings, Radical, Stroke Count, Meanings in English and French.'
        }
      ]
    }
  },
  {
    path: '/kanji/grade2/',
    name: 'kanjiGrade2',
    component: LearnKanji,
    meta: {
      title: 'Grade 2 Kanji',
      metaTags: [
        {
          name: 'description',
          content: 'Learn 160 Jōyō Kanji taught in Grade 2 with Stroke Order Animations, Readings, Radical, Stroke Count, Meanings in English and French.'
        },
        {
          property: 'og:description',
          content: 'Learn 160 Jōyō Kanji taught in Grade 2 with Stroke Order Animations, Readings, Radical, Stroke Count, Meanings in English and French.'
        }
      ]
    }
  },
  {
    path: '/kanji/grade3/',
    name: 'kanjiGrade3',
    component: LearnKanji,
    meta: {
      title: 'Grade 3 Kanji',
      metaTags: [
        {
          name: 'description',
          content: 'Learn 200 Jōyō Kanji taught in Grade 3 with Stroke Order Animations, Readings, Radical, Stroke Count, Meanings in English and French.'
        },
        {
          property: 'og:description',
          content: 'Learn 200 Jōyō Kanji taught in Grade 3 with Stroke Order Animations, Readings, Radical, Stroke Count, Meanings in English and French.'
        }
      ]
    }
  },
  {
    path: '/kanji/grade4/',
    name: 'kanjiGrade4',
    component: LearnKanji,
    meta: {
      title: 'Grade 4 Kanji',
      metaTags: [
        {
          name: 'description',
          content: 'Learn 202 Jōyō Kanji taught in Grade 4 with Stroke Order Animations, Readings, Radical, Stroke Count, Meanings in English and French.'
        },
        {
          property: 'og:description',
          content: 'Learn 202 Jōyō Kanji taught in Grade 4 with Stroke Order Animations, Readings, Radical, Stroke Count, Meanings in English and French.'
        }
      ]
    }
  },
  {
    path: '/kanji/grade5/',
    name: 'kanjiGrade5',
    component: LearnKanji,
    meta: {
      title: 'Grade 5 Kanji',
      metaTags: [
        {
          name: 'description',
          content: 'Learn 193 Jōyō Kanji taught in Grade 5 with Stroke Order Animations, Readings, Radical, Stroke Count, Meanings in English and French.'
        },
        {
          property: 'og:description',
          content: 'Learn 193 Jōyō Kanji taught in Grade 5 with Stroke Order Animations, Readings, Radical, Stroke Count, Meanings in English and French.'
        }
      ]
    }
  },
  {
    path: '/kanji/grade6/',
    name: 'kanjiGrade6',
    component: LearnKanji,
    meta: {
      title: 'Grade 6 Kanji',
      metaTags: [
        {
          name: 'description',
          content: 'Learn 191 Jōyō Kanji taught in Grade 6 with Stroke Order Animations, Readings, Radical, Stroke Count, Meanings in English and French.'
        },
        {
          property: 'og:description',
          content: 'Learn 191 Jōyō Kanji taught in Grade 6 with Stroke Order Animations, Readings, Radical, Stroke Count, Meanings in English and French.'
        }
      ]
    }
  },
  {
    path: '/kanji/:grade/:character/',
    name: 'kanjiItem',
    component: LearnKanji,
    props: true,
    meta: {
      title: '',
      metaTags: [
        {
          name: 'description',
          content: ''
        },
        {
          property: 'og:description',
          content: ''
        }
      ]
    }
  },
  {
    path: '/privacy_policy/',
    name: 'privacy',
    component: PrivacyPolicy,
    meta: {
      title: 'Privacy Policy',
      metaTags: [
        {
          name: 'description',
          content: 'Privacy Policy'
        },
        {
          property: 'og:description',
          content: 'Privacy Policy'
        }
      ]
    }
  },
//////////////// Japanese pages ////////////////
  {
    path: '/jp/',
    name: 'homeJP',
    component: HomeView,
    meta: {
      title: 'にほんごの書き順',
      metaTags: [
        {
          name: 'description',
          content: 'ひらがな・カタカナ・小学校で習う常用漢字1026字を書き順アニメーションで学べます。音読み・訓読み・部首・画数・英語訳・フランス語訳つき。'
        },
        {
          property: 'og:description',
          content: 'ひらがな・カタカナ・小学校で習う常用漢字1026字を書き順アニメーションで学べます。音読み・訓読み・部首・画数・英語訳・フランス語訳つき。'
        }
      ]
    }
  },
  {
    path: '/jp/hiragana/',
    name: 'hiraganaJP',
    component: LearnHiragana,
    meta: {
      title: 'ひらがなの書き順',
      metaTags: [
        {
          name: 'description',
          content: 'ひらがな48文字の書き順をアニメーションで学べます。ひらがなの書き方のコツも紹介。'
        },
        {
          property: 'og:description',
          content: 'ひらがな48文字の書き順をアニメーションで学べます。ひらがなの書き方のコツも紹介。'
        }
      ]
    }
  },
  {
    path: '/jp/hiragana/:character/',
    name: 'hiraganaItemJP',
    component: LearnHiragana,
    props: true,
    meta: {
      title: '',
      metaTags: [
        {
          name: 'description',
          content: ''
        },
        {
          property: 'og:description',
          content: ''
        }
      ]
    }
  },
  {
    path: '/jp/katakana/',
    name: 'katakanaJP',
    component: LearnKatakana,
    meta: {
      title: 'カタカナの書き順',
      metaTags: [
        {
          name: 'description',
          content: 'カタカナ48文字の書き順をアニメーションで学べます。カタカナの書き方のコツも紹介。'
        },
        {
          property: 'og:description',
          content: 'カタカナ48文字の書き順をアニメーションで学べます。カタカナの書き方のコツも紹介。'
        }
      ]
    }
  },
  {
    path: '/jp/katakana/:character/',
    name: 'katakanaItemJP',
    component: LearnKatakana,
    props: true,
    meta: {
      title: '',
      metaTags: [
        {
          name: 'description',
          content: ''
        },
        {
          property: 'og:description',
          content: ''
        }
      ]
    }
  },
  {
    path: '/jp/kanji/',
    name: 'kanjiJP',
    component: LearnKanjiTop,
    meta: {
      title: '漢字の書き順',
      metaTags: [
        {
          name: 'description',
          content: '小学校で習う常用漢字1026字を書き順アニメーション・音読み・訓読み・部首・画数・英語訳・フランス語訳とともに学べます。'
        },
        {
          property: 'og:description',
          content: '小学校で習う常用漢字1026字を書き順アニメーション・音読み・訓読み・部首・画数・英語訳・フランス語訳とともに学べます。'
        }
      ]
    }
  },
  {
    path: '/jp/kanji/grade1/',
    name: 'kanjiGrade1JP',
    component: LearnKanji,
    meta: {
      title: '小学一年生で習う漢字の書き順',
      metaTags: [
        {
          name: 'description',
          content: '小学一年生で習う漢字80字を書き順アニメーション・音読み・訓読み・部首・画数・英語訳・フランス語訳とともに学べます。'
        },
        {
          property: 'og:description',
          content: '小学一年生で習う漢字80字を書き順アニメーション・音読み・訓読み・部首・画数・英語訳・フランス語訳とともに学べます。'
        }
      ]
    }
  },
  {
    path: '/jp/kanji/grade2/',
    name: 'kanjiGrade2JP',
    component: LearnKanji,
    meta: {
      title: '小学二年生で習う漢字の書き順',
      metaTags: [
        {
          name: 'description',
          content: '小学二年生で習う漢字160字を書き順アニメーション・音読み・訓読み・部首・画数・英語訳・フランス語訳とともに学べます。'
        },
        {
          property: 'og:description',
          content: '小学二年生で習う漢字160字を書き順アニメーション・音読み・訓読み・部首・画数・英語訳・フランス語訳とともに学べます。'
        }
      ]
    }
  },
  {
    path: '/jp/kanji/grade3/',
    name: 'kanjiGrade3JP',
    component: LearnKanji,
    meta: {
      title: '小学三年生で習う漢字の書き順',
      metaTags: [
        {
          name: 'description',
          content: '小学三年生で習う漢字200字を書き順アニメーション・音読み・訓読み・部首・画数・英語訳・フランス語訳とともに学べます。'
        },
        {
          property: 'og:description',
          content: '小学三年生で習う漢字200字を書き順アニメーション・音読み・訓読み・部首・画数・英語訳・フランス語訳とともに学べます。'
        }
      ]
    }
  },
  {
    path: '/jp/kanji/grade4/',
    name: 'kanjiGrade4JP',
    component: LearnKanji,
    meta: {
      title: '小学四年生で習う漢字の書き順',
      metaTags: [
        {
          name: 'description',
          content: '小学四年生で習う漢字202字を書き順アニメーション・音読み・訓読み・部首・画数・英語訳・フランス語訳とともに学べます。'
        },
        {
          property: 'og:description',
          content: '小学四年生で習う漢字202字を書き順アニメーション・音読み・訓読み・部首・画数・英語訳・フランス語訳とともに学べます。'
        }
      ]
    }
  },
  {
    path: '/jp/kanji/grade5/',
    name: 'kanjiGrade5JP',
    component: LearnKanji,
    meta: {
      title: '小学五年生で習う漢字の書き順',
      metaTags: [
        {
          name: 'description',
          content: '小学五年生で習う漢字193字を書き順アニメーション・音読み・訓読み・部首・画数・英語訳・フランス語訳とともに学べます。'
        },
        {
          property: 'og:description',
          content: '小学五年生で習う漢字193字を書き順アニメーション・音読み・訓読み・部首・画数・英語訳・フランス語訳とともに学べます。'
        }
      ]
    }
  },
  {
    path: '/jp/kanji/grade6/',
    name: 'kanjiGrade6JP',
    component: LearnKanji,
    meta: {
      title: '小学六年生で習う漢字の書き順',
      metaTags: [
        {
          name: 'description',
          content: '小学六年生で習う漢字191字を書き順アニメーション・音読み・訓読み・部首・画数・英語訳・フランス語訳とともに学べます。'
        },
        {
          property: 'og:description',
          content: '小学六年生で習う漢字191字を書き順アニメーション・音読み・訓読み・部首・画数・英語訳・フランス語訳とともに学べます。'
        }
      ]
    }
  },
  {
    path: '/jp/kanji/:grade/:character/',
    name: 'kanjiItemJP',
    component: LearnKanji,
    props: true,
    meta: {
      title: '',
      metaTags: [
        {
          name: 'description',
          content: ''
        },
        {
          property: 'og:description',
          content: ''
        }
      ]
    }
  },
  {
    path: '/jp/privacy_policy/',
    name: 'privacyJP',
    component: PrivacyPolicy,
    meta: {
      title: 'プライバシーポリシー',
      metaTags: [
        {
          name: 'description',
          content: 'プライバシーポリシー'
        },
        {
          property: 'og:description',
          content: 'プライバシーポリシー'
        }
      ]
    }
  },
//////////////// French pages ////////////////
  {
    path: '/fr/',
    name: 'homeFR',
    component: HomeView,
    meta: {
      title: 'Tracé des Kanji Japonais',
      metaTags: [
        {
          name: 'description',
          content: "Apprenez à écrire les Hiragana, Katakana et 1026 Jōyō Kanji japonais grâce à des animations montrant l'ordre de tracé des traits !"
        },
        {
          property: 'og:description',
          content: "Apprenez à écrire les Hiragana, Katakana et 1026 Jōyō Kanji japonais grâce à des animations montrant l'ordre de tracé des traits !"
        }
      ]
    }
  },
  {
    path: '/fr/hiragana/',
    name: 'hiraganaFR',
    component: LearnHiragana,
    meta: {
      title: 'Apprendre les 48 Hiragana',
      metaTags: [
        {
          name: 'description',
          content: "Apprenez à écrire les 48 Hiragana japonais grâce à des animations montrant l'ordre de tracé des traits !"
        },
        {
          property: 'og:description',
          content: "Apprenez à écrire les 48 Hiragana japonais grâce à des animations montrant l'ordre de tracé des traits !"
        }
      ]
    }
  },
  {
    path: '/fr/hiragana/:character/',
    name: 'hiraganaItemFR',
    component: LearnHiragana,
    props: true,
    meta: {
      title: '',
      metaTags: [
        {
          name: 'description',
          content: ''
        },
        {
          property: 'og:description',
          content: ''
        }
      ]
    }
  },
  {
    path: '/fr/katakana/',
    name: 'katakanaFR',
    component: LearnKatakana,
    meta: {
      title: 'Apprendre les 48 Katakana',
      metaTags: [
        {
          name: 'description',
          content: "Apprenez à écrire les 48 Katakana japonais grâce à des animations montrant l'ordre de tracé des traits !"
        },
        {
          property: 'og:description',
          content: "Apprenez à écrire les 48 Katakana japonais grâce à des animations montrant l'ordre de tracé des traits !"
        }
      ]
    }
  },
  {
    path: '/fr/katakana/:character/',
    name: 'katakanaItemFR',
    component: LearnKatakana,
    props: true,
    meta: {
      title: '',
      metaTags: [
        {
          name: 'description',
          content: ''
        },
        {
          property: 'og:description',
          content: ''
        }
      ]
    }
  },
  {
    path: '/fr/kanji/',
    name: 'kanjiFR',
    component: LearnKanjiTop,
    meta: {
      title: 'Apprendre les 1026 Jōyō Kanji',
      metaTags: [
        {
          name: 'description',
          content: "Apprenez les 1026 Jōyō Kanji grâce à des animations montrant l'ordre de tracé des traits, les lectures, le radical, le nombre de traits et les significations en anglais et en français."
        },
        {
          property: 'og:description',
          content: "Apprenez les 1026 Jōyō Kanji grâce à des animations montrant l'ordre de tracé des traits, les lectures, le radical, le nombre de traits et les significations en anglais et en français."
        }
      ]
    }
  },
  {
    path: '/fr/kanji/grade1/',
    name: 'kanjiGrade1FR',
    component: LearnKanji,
    meta: {
      title: 'Kanji de 1re année',
      metaTags: [
        {
          name: 'description',
          content: "Apprenez les 80 Jōyō Kanji enseignés en 1re année avec des animations montrant l'ordre de tracé des traits, les lectures, le radical, le nombre de traits et les significations en anglais et en français."
        },
        {
          property: 'og:description',
          content: "Apprenez les 80 Jōyō Kanji enseignés en 1re année avec des animations montrant l'ordre de tracé des traits, les lectures, le radical, le nombre de traits et les significations en anglais et en français."
        }
      ]
    }
  },
  {
    path: '/fr/kanji/grade2/',
    name: 'kanjiGrade2FR',
    component: LearnKanji,
    meta: {
      title: 'Kanji de 2e année',
      metaTags: [
        {
          name: 'description',
          content: "Apprenez les 160 Jōyō Kanji enseignés en 2eme année avec des animations montrant l'ordre de tracé des traits, les lectures, le radical, le nombre de traits et les significations en anglais et en français."
        },
        {
          property: 'og:description',
          content: "Apprenez les 160 Jōyō Kanji enseignés en 2eme année avec des animations montrant l'ordre de tracé des traits, les lectures, le radical, le nombre de traits et les significations en anglais et en français."
        }
      ]
    }
  },
  {
    path: '/fr/kanji/grade3/',
    name: 'kanjiGrade3FR',
    component: LearnKanji,
    meta: {
      title: 'Kanji de 3e année',
      metaTags: [
        {
          name: 'description',
          content: "Apprenez les 200 Jōyō Kanji enseignés en 3eme année avec des animations montrant l'ordre de tracé des traits, les lectures, le radical, le nombre de traits et les significations en anglais et en français."
        },
        {
          property: 'og:description',
          content: "Apprenez les 200 Jōyō Kanji enseignés en 3eme année avec des animations montrant l'ordre de tracé des traits, les lectures, le radical, le nombre de traits et les significations en anglais et en français."
        }
      ]
    }
  },
  {
    path: '/fr/kanji/grade4/',
    name: 'kanjiGrade4FR',
    component: LearnKanji,
    meta: {
      title: 'Kanji de 4e année',
      metaTags: [
        {
          name: 'description',
          content: "Apprenez les 202 Jōyō Kanji enseignés en 4eme année avec des animations montrant l'ordre de tracé des traits, les lectures, le radical, le nombre de traits et les significations en anglais et en français."
        },
        {
          property: 'og:description',
          content: "Apprenez les 202 Jōyō Kanji enseignés en 4eme année avec des animations montrant l'ordre de tracé des traits, les lectures, le radical, le nombre de traits et les significations en anglais et en français."
        }
      ]
    }
  },
  {
    path: '/fr/kanji/grade5/',
    name: 'kanjiGrade5FR',
    component: LearnKanji,
    meta: {
      title: 'Kanji de 5e année',
      metaTags: [
        {
          name: 'description',
          content: "Apprenez les 193 Jōyō Kanji enseignés en 5eme année avec des animations montrant l'ordre de tracé des traits, les lectures, le radical, le nombre de traits et les significations en anglais et en français."
        },
        {
          property: 'og:description',
          content: "Apprenez les 193 Jōyō Kanji enseignés en 5eme année avec des animations montrant l'ordre de tracé des traits, les lectures, le radical, le nombre de traits et les significations en anglais et en français."
        }
      ]
    }
  },
  {
    path: '/fr/kanji/grade6/',
    name: 'kanjiGrade6FR',
    component: LearnKanji,
    meta: {
      title: 'Kanji de 6e année',
      metaTags: [
        {
          name: 'description',
          content: "Apprenez les 193 Jōyō Kanji enseignés en 6eme année avec des animations montrant l'ordre de tracé des traits, les lectures, le radical, le nombre de traits et les significations en anglais et en français."
        },
        {
          property: 'og:description',
          content: "Apprenez les 193 Jōyō Kanji enseignés en 6eme année avec des animations montrant l'ordre de tracé des traits, les lectures, le radical, le nombre de traits et les significations en anglais et en français."
        }
      ]
    }
  },
  {
    path: '/fr/kanji/:grade/:character/',
    name: 'kanjiItemFR',
    component: LearnKanji,
    props: true,
    meta: {
      title: '',
      metaTags: [
        {
          name: 'description',
          content: ''
        },
        {
          property: 'og:description',
          content: ''
        }
      ]
    }
  },
  {
    path: '/fr/privacy_policy/',
    name: 'privacyFR',
    component: PrivacyPolicy,
    meta: {
      title: 'Politique de confidentialité',
      metaTags: [
        {
          name: 'description',
          content: 'Politique de confidentialité'
        },
        {
          property: 'og:description',
          content: 'Politique de confidentialité'
        }
      ]
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
//  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

//  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
//  if(nearestWithTitle) {
//    document.title = nearestWithTitle.meta.title;
//  } else if(previousNearestWithMeta) {
//    document.title = previousNearestWithMeta.meta.title;
//  }

  // titleとdescriptionを設定
  var character = ''
  var characterJP = ''
  var characterFR = ''
  var grade = ''
  var kanjiData = ''
  var description = ''
  if (Object.keys(to.params).length !== 0) {
    if (to.name === 'hiraganaItem' || to.name === 'hiraganaItemJP' || to.name === 'hiraganaItemFR') {
      character = to.params.character.toUpperCase()
      dataLoop: for (var k = 0; k < Object.keys(Hiragana.characters).length; k++) {
        for (var j = 0; j < Object.keys(Hiragana.characters[k].list).length; j++) {
          if (Hiragana.characters[k].list[j+1].tag.toUpperCase() == character) {
            characterJP = Hiragana.characters[k].list[j+1].name
            break dataLoop
          }
        }
      }
      if (to.name === 'hiraganaItem') {
        description = 'Learn how to write Japanese Hiragana ' + characterJP + ' [' + character + '] with Animated Stroke Order, Reading, Stroke Count, and Paired Katakana!'
        document.title = 'Hiragana Stroke Order ' + characterJP + ' [' + character + ']'
      } else if (to.name === 'hiraganaItemJP') {
        description = 'ひらがな「' + characterJP + '」の正しい書き方を書き順アニメーションで学ぼう。'
        document.title = 'ひらがな「' + characterJP + '」の書き順'
      } else if (to.name === 'hiraganaItemFR') {
        description = "Apprenez à écrire l'Hiragana japonais " + characterJP + " [" + character + "] avec l'ordre des traits animés, la lecture, le nombre de traits et le Katakana appariés !"
        document.title = "Ordre des traits de l'Hiragana " + characterJP + " [" + character + "]"
      }

    } else if (to.name === 'katakanaItem' || to.name === 'katakanaItemJP' || to.name === 'katakanaItemFR') {
      character = to.params.character.toUpperCase()
      dataLoop: for (var l = 0; l < Object.keys(Hiragana.characters).length; l++) {
        for (var m = 0; m < Object.keys(Hiragana.characters[l].list).length; m++) {
          if (Hiragana.characters[l].list[m+1].tag.toUpperCase() == character) {
            characterJP = Hiragana.characters[l].list[m+1].pairs
            break dataLoop
          }
        }
      }
      if (to.name === 'katakanaItem') {
        description = 'Learn how to write Japanese Katakana ' + characterJP + ' [' + character + '] with Animated Stroke Order, Reading, Stroke Count, and Paired Hiragana!'
        document.title = 'Katakana Stroke Order ' + characterJP + ' [' + character + ']'
      } else if (to.name === 'katakanaItemJP') {
        description = 'カタカナ「' + characterJP + '」の正しい書き方を書き順アニメーションで学ぼう。'
        document.title = 'カタカナ「' + characterJP + '」の書き順'
      } else if (to.name === 'katakanaItemFR') {
        description = "Apprenez à écrire le Katakana japonais " + characterJP + " [" + character + "] avec l'ordre des traits animés, la lecture, le nombre de traits et l'Hiragana appariés !"
        document.title = "" + characterJP + " [" + character + "]"
      }

    } else if (to.name === 'kanjiItem' || to.name === 'kanjiItemJP' || to.name === 'kanjiItemFR') {
      character = to.params.character.toUpperCase().replaceAll("-", " ").replaceAll("*", ",")
      grade = to.params.grade.split('')[5]
      if (grade === '1') {
        kanjiData = KanjiData1.grade[1]
      } else if (grade === '2') {
        kanjiData = KanjiData2.grade[2]
      } else if (grade === '3') {
        kanjiData = KanjiData3.grade[3]
      } else if (grade === '4') {
        kanjiData = KanjiData4.grade[4]
      } else if (grade === '5') {
        kanjiData = KanjiData5.grade[5]
      } else if (grade === '6') {
        kanjiData = KanjiData6.grade[6]
      }
      for (var i = 0; i < Object.keys(kanjiData.kanjiList).length; i++) {
        if (kanjiData.kanjiList[i+1].english[1].toUpperCase() == character) {
          characterJP = kanjiData.kanjiList[i+1].kanji
          characterFR = kanjiData.kanjiList[i+1].french[1]
        }
      }
      if (to.name === 'kanjiItem') {
        description = 'Learn Grade ' + grade + ' Japanese Kanji for ' + characterJP + ' [' + character + '] with Animated Stroke Order, Readings, Radical, Stroke Count, and Meanings in English & French.'
        document.title = 'Grade ' + grade + ' Kanji for ' + characterJP + ' [' + character + ']'
      } else if (to.name === 'kanjiItemJP') {
        description = '小学' + grade + '年生で習う漢字「' + characterJP + '」を書き順アニメーション・音読み・訓読み・部首・画数・英語訳・フランス語訳とともに学ぼう。'
        document.title = '小学' + grade + '年生の漢字「' + characterJP + '」の書き順'
      } else if (to.name === 'kanjiItemFR') {
        if (grade === '1') {
          description = "Apprenez le kanji japonais de " + grade + "re année " + characterJP + " [" + characterFR + "] avec l'ordre des traits animés, les lectures, le radical, le nombre de traits et les significations en anglais et en français."
          document.title = "Kanji de " + grade + "re année " + characterJP + " [" + characterFR + "]"
        } else {
          description = "Apprenez le kanji japonais de " + grade + "e année " + characterJP + " [" + characterFR + "] avec l'ordre des traits animés, les lectures, le radical, le nombre de traits et les significations en anglais et en français."
          document.title = "Kanji de " + grade + "e année " + characterJP + " [" + characterFR + "]"
        }
      }

    }
  } else {
    document.title = to.meta.title
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta')

    Object.keys(tagDef).forEach(key => {
      if (!tagDef[key]) {
        tag.setAttribute(key, description)
      } else {
        tag.setAttribute(key, tagDef[key])
      }
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '')
    return tag
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag))
  next()
});

export default router
