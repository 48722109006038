// Styles
//import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
// 使用するアイコンのみインポートする
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
import { mdiHome, mdiSyllabaryHiragana, mdiSyllabaryKatakana, mdiIdeogramCjk, mdiSchoolOutline,
  mdiLightbulbOnOutline, mdiCheck, mdiOpenInNew, mdiChevronRight, mdiTranslate } from '@mdi/js'

export default createVuetify({
  icons: {
    defaultSet: 'mdi',
    aliases: {
      ...aliases,
      home: mdiHome,
      hiragana: mdiSyllabaryHiragana,
      katakana: mdiSyllabaryKatakana,
      cjk: mdiIdeogramCjk,
      school: mdiSchoolOutline,
      lightbulb: mdiLightbulbOnOutline,
      check: mdiCheck,
      openNew: mdiOpenInNew,
      right: mdiChevronRight,
      translate: mdiTranslate,
    },
    sets: {
      mdi,
    },
  },
})
