import { createStore } from 'vuex'

export default createStore({
  state: {
    language: null,
  },
  getters: {
  },
  mutations: {
    setLanguage (state, language) {
      state.language = language
    },
  },
  actions: {
  },
  modules: {
  }
})
