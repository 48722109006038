<template>
  <div class="home">
    <v-app>
      <v-app-bar color="teal-darken-1" prominent>
        <v-app-bar-nav-icon @click="drawer ? drawer = false : drawer = true"></v-app-bar-nav-icon>
        <v-toolbar-title v-if="language == 'ja'">
          <a href="https://nihongo.maikojapan.com/jp/" style="text-decoration:none; color:white">
            にほんごの書き順
          </a>
        </v-toolbar-title>
        <v-toolbar-title v-if="language == 'en'">
          <a href="https://nihongo.maikojapan.com/" style="text-decoration:none; color:white">
            Nihongo Stroke Order
          </a>
        </v-toolbar-title>
        <v-toolbar-title v-if="language == 'fr'">
          <a href="https://nihongo.maikojapan.com/fr/" style="text-decoration:none; color:white">
            Tracé des Kanji Japonais
          </a>
        </v-toolbar-title>

        <!-- 言語設定 -->
        <v-menu>
          <template v-slot:activator="{ props }">
            <v-btn icon="$translate" v-bind="props"></v-btn>
          </template>
          <v-list>
            <v-list-item @click="setLanguage('ja')">
              <v-list-item-title>日本語</v-list-item-title>
            </v-list-item>
            <v-list-item @click="setLanguage('en')">
              <v-list-item-title>English</v-list-item-title>
            </v-list-item>
            <v-list-item @click="setLanguage('fr')">
              <v-list-item-title>Français</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>
      <!-- サイドメニュー -->
      <v-navigation-drawer v-model="drawer" absolute temporary style="position:fixed;">
        <!-- 日本語メニュー -->
        <v-list v-if="language == 'ja'" nav dense>
          <v-list-item class="font-weight-bold" href="/jp/">
            <v-icon icon="$home" class="mr-1" />Home
          </v-list-item>
          <v-list-item href="/jp/hiragana/">
            <v-icon icon="$hiragana" class="mr-1" />ひらがなの書き順
          </v-list-item>
          <v-list-item href="/jp/katakana/">
            <v-icon icon="$katakana" class="mr-1" />カタカナの書き順
          </v-list-item>
          <v-list-item href="/jp/kanji/">
            <v-icon icon="$cjk" class="mr-1" />漢字の書き順
          </v-list-item>
          <v-list-item
            v-for="grade in gradeListJP"
            :key="grade"
            :value="grade.tag"
            :href="'/jp/kanji/' + grade.tag + '/'"
          >
            <v-icon icon="$school" class="ml-6 mr-2" />{{ grade.name }}の漢字
          </v-list-item>
        </v-list>

        <!-- 英語メニュー -->
        <v-list v-if="language == 'en'" nav dense>
          <v-list-item class="font-weight-bold" href="/">
            <v-icon icon="$home" class="mr-1" />Home
          </v-list-item>
          <v-list-item href="/hiragana/">
            <v-icon icon="$hiragana" class="mr-1" />Hiragana Stroke Order
          </v-list-item>
          <v-list-item href="/katakana/">
            <v-icon icon="$katakana" class="mr-1" />Katakana Stroke Order
          </v-list-item>
          <v-list-item href="/kanji/">
            <v-icon icon="$cjk" class="mr-1" />Kanji Stroke Order
          </v-list-item>
          <v-list-item
            v-for="grade in gradeList"
            :key="grade"
            :value="grade.tag"
            :href="'/kanji/' + grade.tag + '/'"
          >
            <v-icon icon="$school" class="ml-6 mr-2" />{{ grade.name }} Kanji
          </v-list-item>
        </v-list>

        <!-- フランス語メニュー -->
        <v-list v-if="language == 'fr'" nav dense>
          <v-list-item class="font-weight-bold" href="/fr/">
            <v-icon icon="$home" class="mr-1" />Accueil
          </v-list-item>
          <v-list-item href="/fr/hiragana/">
            <v-icon icon="$hiragana" class="mr-1" />Apprendre les Hiragana
          </v-list-item>
          <v-list-item href="/fr/katakana/">
            <v-icon icon="$katakana" class="mr-1" />Apprendre les Katakana
          </v-list-item>
          <v-list-item href="/fr/kanji/">
            <v-icon icon="$cjk" class="mr-1" />Apprendre les 1026 Kanji
          </v-list-item>
          <v-list-item
            v-for="grade in gradeListFR"
            :key="grade"
            :value="grade.tag"
            :href="'/fr/kanji/' + grade.tag + '/'"
          >
            <v-icon icon="$school" class="ml-6 mr-2" />Kanji de {{ grade.name }}
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-main>
        <v-row no-gutters justify="center">
          <!-- Google Adsense TOP -->
          <div id="adsTop" style="width:1000px; max-width:100%; text-align:center;" v-html="adsContentTop"></div>
        </v-row>

        <v-row no-gutters justify="center">
          <v-col cols="12" md="9">
            <router-view />
          </v-col>

          <!-- サイドバー -->
          <v-col cols="12" md="2">

            <!-- Google Adsense SIDE -->
            <div id="adsSide" style="width:300px; max-width:100%;" v-html="adsContentSide"></div>

            <v-list v-if="language == 'ja'" nav dense>
              <v-list-item href="/jp/hiragana/">
                <v-icon icon="$hiragana" class="mr-1" />ひらがなの書き順
              </v-list-item>
              <v-list-item href="/jp/katakana/">
                <v-icon icon="$katakana" class="mr-1" />カタカナの書き順
              </v-list-item>
              <v-list-item href="/jp/kanji/">
                <v-icon icon="$cjk" class="mr-1" />漢字の書き順
              </v-list-item>
              <v-list-item
                v-for="grade in gradeListJP"
                :key="grade"
                :value="grade.tag"
                :href="'/jp/kanji/' + grade.tag + '/'"
              >
                <v-icon icon="$school" class="ml-6 mr-2" />{{ grade.name }}
              </v-list-item>
            </v-list>

            <v-list v-if="language == 'en'" nav dense>
              <v-list-item href="/hiragana/">
                <v-icon icon="$hiragana" class="mr-1" />Hiragana
              </v-list-item>
              <v-list-item href="/katakana/">
                <v-icon icon="$katakana" class="mr-1" />Katakana
              </v-list-item>
              <v-list-item href="/kanji/">
                <v-icon icon="$cjk" class="mr-1" />Kanji
              </v-list-item>
              <v-list-item
                v-for="grade in gradeList"
                :key="grade"
                :value="grade.tag"
                :href="'/kanji/' + grade.tag + '/'"
              >
                <v-icon icon="$school" class="ml-6 mr-2" />{{ grade.name }}
              </v-list-item>
            </v-list>

            <v-list v-if="language == 'fr'" nav dense>
              <v-list-item href="/fr/hiragana/">
                <v-icon icon="$hiragana" class="mr-1" />Hiragana
              </v-list-item>
              <v-list-item href="/fr/katakana/">
                <v-icon icon="$katakana" class="mr-1" />Katakana
              </v-list-item>
              <v-list-item href="/fr/kanji/">
                <v-icon icon="$cjk" class="mr-1" />Kanji
              </v-list-item>
              <v-list-item
                v-for="grade in gradeListFR"
                :key="grade"
                :value="grade.tag"
                :href="'/fr/kanji/' + grade.tag + '/'"
              >
                <v-icon icon="$school" class="ml-6 mr-2" />{{ grade.name }}
              </v-list-item>
            </v-list>

          </v-col>
        </v-row>

        <v-row no-gutters justify="center">
          <!-- Google Adsense BOTTOM -->
          <div id="adsBottom" style="width:1000px; max-width:100%;" v-html="adsContentBottom"></div>
        </v-row>

      </v-main>

      <!-- フッター -->
      <v-footer class="bg-teal-darken-1">
        <v-row justify="center" no-gutters>
          <template v-if="language == 'ja'">
            <v-btn
              v-for="link in linksJP"
              :key="link"
              color="white"
              variant="text"
              rounded="xl"
              :href="link.url"
              target="_blank"
              class="no-uppercase"
            >
              {{ link.urlname }}
              <v-icon icon="$openNew" class="ml-1" />
            </v-btn>
          </template>
          <template v-if="language == 'en'">
            <v-btn
              v-for="link in links"
              :key="link"
              color="white"
              variant="text"
              rounded="xl"
              :href="link.url"
              target="_blank"
              class="no-uppercase"
            >
              {{ link.urlname }}
              <v-icon icon="$openNew" class="ml-1" />
            </v-btn>
          </template>
          <template v-if="language == 'fr'">
            <v-btn
              v-for="link in linksFR"
              :key="link"
              color="white"
              variant="text"
              rounded="xl"
              :href="link.url"
              target="_blank"
              class="no-uppercase"
            >
              {{ link.urlname }}
              <v-icon icon="$openNew" class="ml-1" />
            </v-btn>
          </template>

          <v-col class="text-center my-1" cols="12">
            <template v-if="language == 'ja'">
              <v-btn
                v-for="link in siteInfoJP"
                :key="link"
                color="white"
                variant="text"
                rounded="xl"
                :href="link.url"
                class="no-uppercase"
              >
              {{ link.urlname }}
              </v-btn>
            </template>
            <template v-if="language == 'en'">
              <v-btn
                v-for="link in siteInfo"
                :key="link"
                color="white"
                variant="text"
                rounded="xl"
                :href="link.url"
                class="no-uppercase"
              >
              {{ link.urlname }}
              </v-btn>
            </template>
            <template v-if="language == 'fr'">
              <v-btn
                v-for="link in siteInfoFR"
                :key="link"
                color="white"
                variant="text"
                rounded="xl"
                :href="link.url"
                class="no-uppercase"
              >
              {{ link.urlname }}
              </v-btn>
            </template>
          </v-col>

          <v-col class="text-center my-2" cols="12">
            Copyright © {{ new Date().getFullYear() }} <a href="https://maikojapan.com/" class="text-decoration-none" style="color:white" target="_blank">Maiko Japan</a>
          </v-col>
        </v-row>
      </v-footer>
    </v-app>
  </div>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    drawer: false,
    adsContentTop: '',
    adsContentBottom: '',
    adsContentSide: '',

    gradeList: [
      { name: "Grade 1", tag: "grade1" },
      { name: "Grade 2", tag: "grade2" },
      { name: "Grade 3", tag: "grade3" },
      { name: "Grade 4", tag: "grade4" },
      { name: "Grade 5", tag: "grade5" },
      { name: "Grade 6", tag: "grade6" }
    ],
    gradeListJP: [
      { name: "小学一年生", tag: "grade1" },
      { name: "小学二年生", tag: "grade2" },
      { name: "小学三年生", tag: "grade3" },
      { name: "小学四年生", tag: "grade4" },
      { name: "小学五年生", tag: "grade5" },
      { name: "小学六年生", tag: "grade6" }
    ],
    gradeListFR: [
      { name: "1re année", tag: "grade1" },
      { name: "2e année", tag: "grade2" },
      { name: "3e année", tag: "grade3" },
      { name: "4e année", tag: "grade4" },
      { name: "5e année", tag: "grade5" },
      { name: "6e année", tag: "grade6" }
    ],

    links: [
      {
        urlname: "Hiragana in PDF",
        url: "https://maikojapan.com/hiragana-writing-practice-sheets/"
      },
      {
        urlname: "Katakana in PDF",
        url: "https://maikojapan.com/katakana-writing-practice-sheets/"
      },
      {
        urlname: "2136 Jōyō Kanji",
        url: "https://maikojapan.com/2136-joyo-kanji-in-pdf/"
      },
      {
        urlname: "72 Seasons with Ukiyo-e",
        url: "https://72seasons.maikojapan.com/en/"
      }
    ],
    linksJP: [
      {
        urlname: "浮世絵で見る七十二候",
        url: "https://72seasons.maikojapan.com/"
      },
      {
        urlname: "各地の年間天気グラフ",
        url: "https://weather.monsieurlangue.com/"
      },
      {
        urlname: "フランス語クイズ",
        url: "https://quiz.monsieurlangue.com/"
      }
    ],
    linksFR: [
      {
        urlname: "72 Saisons du Japon",
        url: "https://72seasons.maikojapan.com/fr/"
      },
      {
        urlname: "TekuTeku Japan",
        url: "https://www.tekuteku-japan.com/"
      },
      {
        urlname: "Kanji Flashcards & Free Study Sheets",
        url: "https://maikojapan.com/"
      }
    ],

    siteInfo: [
      { urlname: "Privacy Policy", url: "/privacy_policy/" }
    ],
    siteInfoJP: [
      { urlname: "利用規約", url: "/jp/privacy_policy/" }
    ],
    siteInfoFR: [
      { urlname: "Politique de confidentialité", url: "/fr/privacy_policy/" }
    ],
  }),
  mounted() {
    if (document.getElementById('divadsenseTop')) {
      this.adsContentTop = document.getElementById('divadsenseTop').innerHTML
    }
    if (document.getElementById('divadsenseBottom')) {
      this.adsContentBottom = document.getElementById('divadsenseBottom').innerHTML
    }
    if (document.getElementById('divadsenseSide')) {
      this.adsContentSide = document.getElementById('divadsenseSide').innerHTML
    }

    if (window.location.pathname.includes("/jp/")) {
      this.$store.commit('setLanguage', 'ja')
    } else if (window.location.pathname.includes("/fr/")) {
      this.$store.commit('setLanguage', 'fr')
    } else {
      this.$store.commit('setLanguage', 'en')
    }
  },
  methods: {
    setLanguage (language) {
      this.$store.commit('setLanguage', language)
      var currentLocation = window.location.pathname
      currentLocation = currentLocation.replace("/jp/", "/")
      currentLocation = currentLocation.replace("/fr/", "/")
      if (language == "ja") {
        window.location.href = '/jp' + currentLocation
      } else if (language == "en") {
        window.location.href = currentLocation
      } else if (language == "fr") {
        window.location.href = '/fr' + currentLocation
      }
    }
  },
  computed: {
    language: {
      get(){
        return this.$store.state.language
      },
      set(value){
        this.$store.commit('setLanguage', value)
      }
    },
  },
}
</script>

<style>
.no-uppercase {
     text-transform: initial !important;
}
</style>
